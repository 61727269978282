<template>
    <div>
        <div class="container-card-cuota" v-if="!loaderIsActive">
            <div class="bg-content-icon-response mb-4" :class="[(respuestaTransaccion.state_pol == 4) ? 'approved' : (respuestaTransaccion.state_pol > 4) ? 'rejected' : 'pending']">
                <div class="content-icon-response">
                    <img v-if="respuestaTransaccion.state_pol == 4" src="@/assets/shield-check.svg" alt="icon transaction">
                    <img v-else-if="respuestaTransaccion.state_pol > 4" src="@/assets/shield-exclamation.svg" alt="icon transaction">
                    <img v-else src="@/assets/shield-pending.svg" alt="icon transaction">
                </div>
            </div>
            <h2 class="title mb-2">{{ respuestaTransaccion.title }}</h2>
            <p class="subtitle-container mb-4" v-if="respuestaTransaccion.state_pol == 4">Se ha registrado tu pago</p>
            <p class="subtitle-container mb-4" v-else-if="respuestaTransaccion.state_pol > 4">No se ha podido validar tu pago.</p>
            <p class="subtitle-container mb-4" v-else>Tu pago se encuentra en proceso de validación</p>
            <div class="container-response-transaction">

                <div class="item-response-transaction">
                    <div class="title-item">
                        Referencia de pago
                    </div>
                    <div v-text="respuestaTransaccion.referencia_pago" />
                </div>
                
                <div class="item-response-transaction">
                    <div class="title-item">
                        Referencia de la transacción
                    </div>
                    <div v-text="respuestaTransaccion.referencia_transaccion" />
                </div>

                <div class="item-response-transaction">
                    <div class="title-item">
                        Fecha
                    </div>
                    <div v-text="respuestaTransaccion.fecha" />
                </div>

                <div class="item-response-transaction">
                    <div class="title-item">
                        Valor total
                    </div>
                    <div v-text="`$${respuestaTransaccion.valor_total}`" />
                </div>

                <div class="item-response-transaction">
                    <div class="title-item">
                        Descripción
                    </div>
                    <div v-text="respuestaTransaccion.descripcion" />
                </div>

                <div class="item-response-transaction">
                    <div class="title-item">
                        Entidad
                    </div>
                    <div v-text="respuestaTransaccion.entidad" />
                </div>

            </div>

            <!-- <div class="mt-3" v-if="respuestaTransaccion.state_pol == 4">
                <a class="btn btn-outline-primary w-100 fw-bold pt-2 pb-2" href="https://quac.co">Ir a la tienda</a>
            </div> -->
            <div class="mt-3" v-if="respuestaTransaccion.state_pol > 4">
                <button class="btn btn-outline-primary w-100 fw-bold pt-2 pb-2" type="button" @click="irSeleccionarCuota">
                    Intentar nuevamente
                </button>
            </div>
            <EstrategiaReventa
                v-if="showModalCuponEstrategiaReventa"
                :info-notification="cuponEstrategiaReventa"
            />
        </div>
        <div v-else class="container-skeleton-transaccion">
            <div class="container-skeleton-transaccion--img skeleton-transition" />
            <div class="container-skeleton-transaccion--titulo skeleton-transition" />
            <div class="container-skeleton-transaccion--subtitulo skeleton-transition" />
            <div class="container-skeleton-transaccion--contenido skeleton-transition" />
        </div>
    </div>
</template>
<script>
import EstrategiaReventa from './EstrategiaReventa.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
export default{
    components:{
        EstrategiaReventa,
    },
    data(){
        return {
        }
    },
    mounted(){
        if(this.$route.name == 'ResponseBancolombia'){
            this.obtenerRespuestaBancolombia({transferState: this.$route.query.transferState,transferCode: this.$route.query.transferCode});
        }else if(this.$route.name == 'ResponsePayu'){
            this.obtenerRespuestaPayu(
                this.$route.query.payment_id
                    ? this.$route.query.payment_id
                    : this.$route.query.referenceCode
            );
        }
    },
    computed:{
        ...mapState(['respuestaTransaccion','cuponEstrategiaReventa','showModalCuponEstrategiaReventa','loaderIsActive','documentoCliente','celularCliente','nitComercio']),
    },
    methods:{
        ...mapMutations(['agregarRespuestaTransaccion','gestionarValidarDocumento','gestionarShowArrowBack','cambiarDocumentoCliente','cambiarCelularEnmascaradoCliente']),
        ...mapActions(['obtenerRespuestaBancolombia','obtenerRespuestaPayu']),
        numberFormat(val){
            return new Intl.NumberFormat("es-CO", {minimumFractionDigits: 0,currency: 'COP',style: 'currency'}).format(val);
        },
        irSeleccionarCuota(){
            this.gestionarValidarDocumento(true);
            this.gestionarShowArrowBack(true);
            this.cambiarDocumentoCliente(this.documentoCliente);
            this.cambiarCelularEnmascaradoCliente(this.celularCliente.toString());
            return this.$store.dispatch('validarCelular',{'client_phone': this.celularCliente.toString(),'client_document': this.documentoCliente,nit_business: this.nitComercio});
        },
    }
}
</script>
<style lang="scss">
.container-card-cuota{
    color: #2E1380;
}
.container-response-transaction{
    margin: 0 auto;
    max-width: 400px;
    background-color: #E9E8F1;
    border-radius: 10px;
    margin-bottom: 2rem;
    .item-response-transaction{
        padding: 1rem;
        border-bottom: 1px solid #D8D8D8;
        color: black;
        .title-item{
            font-weight: bold;
            color: black;
        }
        &:last-child{
            border-bottom: 0;
        }
    }
}

.bg-content-icon-response{
    margin: 0 auto;
    height: 110px;
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    .content-icon-response{
        height: 80px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    &.rejected{
        background-color: #FFD9D9;
        .content-icon-response{
            background-color: #FF6464;
        }
    }
    &.approved{
        background-color: #C0ECE9;
        .content-icon-response{
            background-color: #6DC8C2;
        }
    }
    &.pending{
        background-color: #ffeba3;
        .content-icon-response{
            background-color: #ffd43b;
        }
    }
}
.container-skeleton-transaccion{
    padding-top: 5rem;
    margin-right: auto;
    margin-left: auto;
    max-width: 400px;
    width: 90%;
    .container-skeleton-transaccion--img{
        height: 24px;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #D8D8D8;
        margin: 0 auto;
        margin-bottom: 1rem;
    }
    .container-skeleton-transaccion--titulo{
        height: 24px;
        width: 80%;
        background-color: #D8D8D8;
        margin: 0 auto;
        margin-bottom: 1rem;
        border-radius: 30px;
    }
    .container-skeleton-transaccion--subtitulo{
        height: 16px;
        width: 70%;
        background-color: #D8D8D8;
        margin: 0 auto;
        margin-bottom: 1rem;
        border-radius: 30px;
    }
    .container-skeleton-transaccion--contenido{
        width: 100%;
        background-color: #D8D8D8;
        margin: 0 auto;
        border-radius: 10px;
        margin-bottom: 1rem;
        aspect-ratio: 1/1.2;
    }
}
</style>